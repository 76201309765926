(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name neo.directive:neoList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="neo">
   <file name="index.html">
   <neo-list></neo-list>
   </file>
   </example>
   *
   */
  angular
  .module('neo')
  .directive('neoList', neoList);

  function neoList() {
    return {
      restrict: 'E',
      scope: {
        data: '='
      },
      templateUrl: 'directives/neo-list/neo-list-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope) {
        var vm = this;
        vm.name = 'neoList';

        $scope.data = $scope.data || {};

        vm.title = $scope.data.title;
        vm.cardWidth = $scope.data.cardWidth || 70;
        vm.notFoundMessage = $scope.data.notFoundMessage;
        vm.headers = $scope.data.headers;
        vm.values = sortValues($scope.data.values, $scope.data.defaultOrderBy, $scope.data.defaultTypeOrderBy);
        vm.state = $scope.data.state;
        vm.defaultOrderBy = undefined;
        vm.filters = $scope.data.filters;

        angular.forEach(vm.filters, function (filter) {
          if (!angular.isUndefined(filter.options)) {
            filter.options.sort(function (a, b) {
              return a[filter.showField].toLowerCase().localeCompare(b[filter.showField].toLowerCase());
            });
          }
        });

        vm.filterPromise = $scope.data.filterPromise;
        vm.filterFunction = $scope.data.filterFunction;
        vm.filterValues = $scope.data.filterValues;
        if (angular.isUndefined($scope.data.areRowsClickable) || $scope.data.areRowsClickable === null || $scope.data.areRowsClickable === true) {
          vm.cursor = "pointer"
        } else {
          vm.cursor = "default"
        }

        vm.showList = true;
        vm.selected = [];

        vm.select = undefined;
        vm.getStateLink = getStateLink;

        vm.applyFilters = applyFilters;
        vm.filteredListHasSomeItem = filteredListHasSomeItem;
        vm.isDisabledFilter = isDisabledFilter;
        vm.isDisabledFilterButton = false;


        if (angular.isDefined(vm.filterValues)) {
          var instantSearch = true;
          vm.showList = false;
          angular.forEach(vm.filters, function (filter) {
            filter.selectedItem = vm.filterValues[filter.label];
            if (angular.isUndefined(vm.filterValues[filter.label])) {

              instantSearch = false;
            }
          });
          if (instantSearch) {
            applyFilters();
          }
        }
        angular.forEach(vm.filters, function (filter) {
          if (angular.isDefined(filter.eventTrigger)) {
            $scope.$watch(
              function () {
                return filter.selectedItem;
              },
              function () {
                if (existsValue(filter.selectedItem)) {
                  var params = {};
                  params[filter.filterParam] = filter.selectedItem[filter.filterField];
                  filter.eventTrigger(params);
                }
              });
          }
          if (angular.isDefined(filter.eventRefreshData)) {
            $scope.$watch(
              function () {
                return filter.selectedItem;
              },
              function () {
                vm.values = sortValues(filter.eventRefreshData(), $scope.data.defaultOrderBy, $scope.data.defaultTypeOrderBy);
              });
          }

          $scope.$watch(
            function () {
              return filter.options;
            },
            function () {
              if (angular.isDefined(filter.options) && (filter.options.length === 1)) {
                filter.selectedItem = filter.options[0];
              }
              if (areAllFilterSelected()) {
                vm.isDisabledFilterButton = isDisabledFilterButton();
                applyFilters();
              }
            });
        });

        function isDisabledFilterButton() {
          return _.every(vm.filters, function (filter) {
            return (angular.isDefined(filter.options) && filter.options.length === 1);
          });
        }

        function areAllFilterSelected() {
          return _.every(vm.filters, function (filter) {
            return (angular.isDefined(filter.selectedItem) && filter.selectedItem !== null);
          });
        }

        function existsValue(value) {
          return angular.isDefined(value) && value !== null;
        }

        function filteredListHasSomeItem() {
          return !angular.isUndefined(vm.filteredValues) && vm.filteredValues.length;
        }

        function sortValues(values, defaultOrderBy, defaultTypeOrderBy) {
          if (angular.isUndefined(defaultTypeOrderBy)) {
            return _.sortByAll(values, defaultOrderBy);
          } else {
            return _.sortByOrder(values, defaultOrderBy, defaultTypeOrderBy);
          }
        }

        function isDisabledFilter(dependency) {
          if (angular.isDefined(dependency)) {
            return vm.filters[dependency].selectedItem === null;
          }
          return false;
        }

        function getStateLink(value) {
          if (angular.isDefined(vm.state)) {
            var params = {};
            angular.forEach(vm.state.params, function (param) {
              params[param.key] = value[param.value];
            });
            return vm.state.slink + '(' + JSON.stringify(params) + ')';
          }
        }

        function applyFilters() {
          vm.showList = true;
          var params = {};
          angular.forEach(vm.filters, function (filter) {
            params[filter.filterParam] = filter.selectedItem[filter.filterField];
          });
          vm.filterPromise(params).then(function (data) {
            vm.values = sortValues(vm.filterFunction(data), $scope.data.defaultOrderBy, $scope.data.defaultTypeOrderBy);

          });
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };


  }
}());
